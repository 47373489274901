import { ActionContext } from "vuex";
import { rootState } from "@/store/rootState";
import { PromoCodesDto } from "./../api/interfaces/promo-codes/promo-codes.dto";
import { api } from "@/api/Api";

import { IApiResponse } from "@/api/interfaces/IApi";
import { IPromoCode } from "./interfaces/promo-codes/promoCodes";
import { IApiPromoResponse } from "@/api/interfaces/promo-codes/common";

export enum promoCodesTypes {
  personal = "personal",
  nonPersonal = "nonPersonal"
}

export type IPromoCodesState = {
  promoCodesList: IPromoCodesStateLists;
  totalPromoCodes: IPromoCodesTotalStateLists;
};

export interface IPromoCodesStateLists {
  [promoCodesTypes.personal]: IPromoCode[];
  [promoCodesTypes.nonPersonal]: IPromoCode[];
}
export interface IPromoCodesTotalStateLists {
  [promoCodesTypes.personal]: number;
  [promoCodesTypes.nonPersonal]: number;
}

export interface promoCodesState {
  promoCodesList: {
    [promoCodesTypes.personal]: IPromoCode[];
    [promoCodesTypes.nonPersonal]: IPromoCode[];
  };
  totalPromoCodes: {
    [promoCodesTypes.personal]: number;
    [promoCodesTypes.nonPersonal]: number;
  };
}

const initialState: promoCodesState = {
  promoCodesList: {
    [promoCodesTypes.personal]: [],
    [promoCodesTypes.nonPersonal]: []
  },
  totalPromoCodes: {
    [promoCodesTypes.personal]: 0,
    [promoCodesTypes.nonPersonal]: 0
  }
};

export interface PromoQuery {
  perPage: number;
  page: number;
  personal: boolean;
}

export type TPromoCodesKeys = keyof IPromoCodesStateLists;

export const promoCodesModule = {
  state(): promoCodesState {
    return { ...initialState };
  },

  getters: {
    promoCodesList: (state: promoCodesState) => {
      return <T extends TPromoCodesKeys>(type: T): IPromoCodesStateLists[T] => {
        return state.promoCodesList[type];
      };
    },
    totalPromoCodes: (state: promoCodesState) => {
      return <T extends TPromoCodesKeys>(
        type: T
      ): IPromoCodesTotalStateLists[T] => {
        return state.totalPromoCodes[type];
      };
    }
  },

  mutations: {
    setPromoCodes<T extends TPromoCodesKeys>(
      state: promoCodesState,
      {
        promoCodeType,
        promo
      }: {
        promoCodeType: T;
        promo: IPromoCodesStateLists[T];
      }
    ) {
      state.promoCodesList[promoCodeType] = promo;
    },
    setTotalPromoCodes<T extends TPromoCodesKeys>(
      state: promoCodesState,
      {
        promoCodeType,
        total
      }: {
        promoCodeType: T;
        total: IPromoCodesTotalStateLists[T];
      }
    ) {
      state.totalPromoCodes[promoCodeType] = total;
    }
  },

  actions: {
    async fetchPromoCodes(
      ctx: ActionContext<IPromoCodesState, rootState>,
      payload: {
        viewConfig: PromoQuery;
      }
    ): Promise<IApiResponse> {
      const { data, success, message, status } = <IApiPromoResponse>(
        await api.getPromoCodes(payload.viewConfig)
      );
      const { result, total } = data;
      if (result) {
        ctx.commit("setPromoCodes", {
          promoCodeType: payload.viewConfig.personal
            ? "personal"
            : "nonPersonal",
          promo: result
        });
        ctx.commit("setTotalPromoCodes", {
          promoCodeType: payload.viewConfig.personal
            ? "personal"
            : "nonPersonal",
          total
        });
      }

      return { success, message, status };
    },
    async actionWithPromoCode(
      _: any,
      { data, id }: { data: PromoCodesDto; id: number | null }
    ) {
      if (data?.id && data?.title) {
        const { success, message, status } = <IApiPromoResponse>(
          await api.updatePromoCode(data.id, data)
        );
        return { success, message, status };
      } else if (data?.title && !data?.id) {
        const { success, message, status } = <IApiPromoResponse>(
          await api.createPromoCode(data)
        );
        return { success, message, status };
      } else if (id) {
        const { success, message, status } = <IApiPromoResponse>(
          await api.deletePromoCode(id)
        );
        return { success, message, status };
      }
    }
  }
};
