import { ActionContext } from "vuex";

import {
  IBaseLocation,
  IBasePackage,
  ISubregion
} from "@/api/services/packages/esim/location-packages/types";
import { packageLocationTypes } from "@/hooks/esim/location-packages/types/locationPackage.types";
import { rootState } from "@/store/rootState";
import { api } from "@/api/Api";
import {
  IDeleteLocationDto,
  IFetchLocationsDto,
  selectedLocationListType,
  selectedLocationType
} from "@/api/services/locations/locationTypes";
import { IFetchSelectedLocationsDto } from "@/api/services/locations/locationsService";

export interface ILocationCountry extends IBaseLocation {
  packages: IBasePackage[];
  countryPlans?: any;
}
export interface ILocationRegion extends IBaseLocation {
  subregions: ISubregion[];
  countries: ILocationCountry[];
  regionPlans?: any;
}

export interface ISelectedLocations {
  [id: number]: {
    country?: ILocationCountry[];
    subregion?: ISubregion[];
  };
}

export interface INotExpendedLocationsLists {
  countries: ILocationCountry[];
  subregions: ISubregion[];
}

export type ILocationsState = {
  lists: ILocationsStateLists;
  total: number;
  notExtendedCountries: ILocationCountry[];
  notExtendedSubregions: ISubregion[];
};

export interface ILocationsStateLists {
  [packageLocationTypes.countries]: ILocationCountry[];
  [packageLocationTypes.regions]: ILocationRegion[];
  [packageLocationTypes.subregions]: ISubregion[];
}

const initialState: ILocationsState = {
  lists: {
    [packageLocationTypes.countries]: [],
    [packageLocationTypes.regions]: [],
    [packageLocationTypes.subregions]: []
  },
  notExtendedCountries: [],
  notExtendedSubregions: [],
  total: 0
};

export type TLocationKeys = keyof ILocationsStateLists;

export const locations = {
  state(): ILocationsState {
    return { ...initialState };
  },

  getters: {
    getLocations(state: ILocationsState) {
      return <T extends TLocationKeys>(type: T): ILocationsStateLists[T] => {
        return state.lists[type];
      };
    },

    totalLocations: ({ total }: ILocationsState) => total,

    notExtendedSubregions: ({ notExtendedSubregions }: ILocationsState) =>
      notExtendedSubregions,

    notExtendedCountries: ({ notExtendedCountries }: ILocationsState) =>
      notExtendedCountries
  },

  mutations: {
    setTotalLocations(state: ILocationsState, total: number) {
      state.total = total;
    },

    setLocations<T extends TLocationKeys>(
      state: ILocationsState,
      {
        locationType,
        locations
      }: {
        locationType: T;
        locations: ILocationsStateLists[T];
      }
    ) {
      state.lists[locationType] = locations;
    },

    setNotExtendedCountries(
      state: ILocationsState,
      countries: ILocationCountry[]
    ) {
      state.notExtendedCountries = countries;
    },

    setNotExtendedSubregions(state: ILocationsState, subregions: ISubregion[]) {
      state.notExtendedSubregions = subregions;
    }
  },

  actions: {
    async fetchLocationsList<T extends TLocationKeys>(
      ctx: ActionContext<ILocationsState, rootState>,
      payload: {
        query: IFetchLocationsDto;
        locationType: T;
      }
    ) {
      const { data: locations, ...response } = await api.fetchLocationsList(
        payload.query,
        payload.locationType
      );

      ctx.commit("setLocations", {
        locationType: payload.locationType,
        locations
      });

      ctx.commit("setTotalLocations", response.total);

      return { ...response, data: locations };
    },

    async deleteLocation<T extends TLocationKeys>(
      ctx: ActionContext<ILocationsState, rootState>,
      payload: IDeleteLocationDto<T>
    ) {
      const { success, ...response } = await api.deleteLocation(payload);

      if (success) {
        ctx.commit("setLocations", {
          locationType: payload.locationType,
          locations:
            ctx.getters
              .getLocations(payload.locationType)
              ?.filter(({ id }: IBasePackage) => id !== payload.id) || []
        });
      }

      return { ...response, success };
    },

    async fetchSelectedLocations<
      List extends selectedLocationListType,
      Location extends selectedLocationType
    >(
      ctx: ActionContext<ILocationsState, rootState>,
      payload: IFetchSelectedLocationsDto<List, Location>
    ) {
      return await api.fetchSelectedLocations(payload);
    },

    fetchNotExtendedLocations(
      ctx: ActionContext<ILocationsState, rootState>,
      types?: TLocationKeys[]
    ) {
      const locationTypes: TLocationKeys[] = types || [
        packageLocationTypes.countries,
        packageLocationTypes.subregions
      ];

      return Promise.all(
        locationTypes.map(async type => {
          return await api.fetchLocationsList(
            {
              extended: false
            },
            type
          );
        })
      )
        .then(([countriesResponse, subregionsResponse]) => {
          const {
            data: countriesList,
            success: countriesSuccess,
            message: countriesMessage
          } = countriesResponse;

          const {
            data: subregionsList,
            success: subregionsSuccess,
            message: subregionsMessage
          } = subregionsResponse;

          countriesSuccess &&
            ctx.commit("setNotExtendedCountries", countriesList);

          subregionsSuccess &&
            ctx.commit("setNotExtendedSubregions", subregionsList);

          !countriesSuccess &&
            countriesMessage &&
            ctx.dispatch("showErrorNotification", countriesMessage, {
              root: true
            });

          !subregionsSuccess &&
            subregionsMessage &&
            ctx.dispatch("showErrorNotification", subregionsMessage, {
              root: true
            });

          return countriesSuccess || subregionsSuccess;
        })
        .catch(() => {
          ctx.dispatch("showErrorNotification", "Server error", { root: true });
          return false;
        });
    }
  }
};
