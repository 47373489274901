import {
  apiRequestFileType,
  apiRequestType,
  IApiResponse,
  IGApiResponse
} from "@/api/interfaces/IApi";
import { queryObjectToUrlString } from "@/utills/queryObjectToUrlString";
import {
  esimProfile,
  esimQrData,
  esimType,
  fetchEsimProfilesDto,
  IEsimServerFull
} from "./profiles.types";

export const profilesService = {
  async fetchEsimServers(
    request: apiRequestType,
    id: number,
    year: string
  ): Promise<IGApiResponse<IEsimServerFull[]>> {
    return (await request(`esim/server/type/${id}?year=${year}`, "get")) as IGApiResponse<
      IEsimServerFull[]
    >;
  },

  async fetchEsimTypes(
    request: apiRequestType
  ): Promise<IGApiResponse<esimType[]>> {
    return (await request("/esim/types/all", "get")) as IGApiResponse<
      esimType[]
    >;
  },

  async fetchEsimProfiles(
    request: apiRequestType,
    data: fetchEsimProfilesDto
  ): Promise<IGApiResponse<esimProfile[]>> {
    const { serverId, ...params } = data;
    const query = queryObjectToUrlString(params);

    return (await request(
      `/esim/list/${serverId}?${query}`,
      "get"
    )) as IGApiResponse<esimProfile[]>;
  },

  async getEsimQr(
    request: apiRequestType,
    listId: number
  ): Promise<IApiResponse> {
    return await request(
      `${process.env.VUE_APP_BASE_API_URL}/esim/qr/${listId}`,
      "get"
    );
  },

  async importProfile(
    request: apiRequestFileType,
    table: File,
    serverId: number,
    onProgress?: (progress: number) => void
  ) {
    let progress = 0;

    const res = await request(
      `/esim/load-excel?type=esims&id=${serverId}`,
      "post",
      table,
      "file",
      (evt: ProgressEvent) => {
        const { loaded, total } = evt;
        progress = parseInt(Math.round((loaded / total) * 100).toString());
        onProgress?.(progress);
      }
    );

    return res;
  },

  async importBundle(
    request: apiRequestFileType,
    table: File,
    onProgress?: (progress: number) => void
  ) {
    let progress = 0;

    const res = await request(
      `/esim/load-excel?type=bundles`,
      "post",
      table,
      "file",
      (evt: ProgressEvent) => {
        const { loaded, total } = evt;
        progress = parseInt(Math.round((loaded / total) * 100).toString());
        onProgress?.(progress);
      }
    );

    return res;
  }
};
