import { api } from "@/api/Api";
import { Commit } from "vuex";
import {
  fetchLocationPackagesDto,
  fetchLocationPackagesRatesDto,
  ILocationPackage,
  IPackage,
  IRemoveLocationPackageDto,
  IUpdateLocationPackageDto
} from "@/api/services/packages/esim/location-packages/types";
import {
  IFetchLocationPackagesRatesResponse,
  IFetchLocationPackagesResponse
} from "@/api/services/packages/esim/location-packages/responseTypes";
import {
  packageLocationTypes,
  TPackageLocation
} from "@/hooks/esim/location-packages/types/locationPackage.types";
import { ILocationPackageListItemEditDto } from "@/hooks/esim/location-packages/types/useLocationPackageListType";
import { IApiResponse } from "@/api/interfaces/IApi";
import { locationKeys } from "@/hooks/esim/location-packages/modules/locationKeys";
import { IPlan } from "@/api/interfaces/payments/common";
import { ILocationPlan } from "@/api/interfaces/esim-plans/common";

export interface IPlanRate {
  planId: number;
  rate: number;
}

export interface IZoneRates {
  zoneId: number;
  plans: IPlanRate[];
}

export interface IRegionRates {
  regionId: number;
  plans: IPlanRate[];
}

export interface ISubregionRates {
  subregionId: number;
  plans: IPlanRate[];
}

export interface ILocationRates {
  zoneId?: number;
  regionId?: number;
  subregionId?: number;
  plans: IPlanRate[];
}

export type TLocationRates =
  | IZoneRates[]
  | IRegionRates[]
  | ISubregionRates[]
  | [];

export interface ILocationPackagesState {
  locationPackages: {
    [packageLocationTypes.countries]: {
      packages: ILocationPackage[];
      total: number;
      rates: TLocationRates;
    };
    [packageLocationTypes.regions]: {
      packages: ILocationPackage[];
      total: number;
      rates: TLocationRates;
    };
    [packageLocationTypes.subregions]: {
      packages: ILocationPackage[];
      total: number;
      rates: TLocationRates;
    };
  };
}

const initialState = {
  locationPackages: {
    [packageLocationTypes.countries]: {
      packages: [],
      total: 0,
      rates: []
    },
    [packageLocationTypes.regions]: {
      packages: [],
      total: 0,
      rates: []
    },
    [packageLocationTypes.subregions]: {
      packages: [],
      total: 0,
      rates: []
    }
  }
};

export const locationPackages = {
  state(): ILocationPackagesState {
    return initialState;
  },

  getters: {
    locationPackages: ({ locationPackages }: ILocationPackagesState) => {
      return (locationType: TPackageLocation) => {
        return locationPackages[locationType].packages;
      };
    },

    totalLocationPackages: ({ locationPackages }: ILocationPackagesState) => {
      return (locationType: TPackageLocation): number => {
        return locationPackages[locationType].total;
      };
    },

    locationRates: ({ locationPackages }: ILocationPackagesState) => {
      return (locationType: TPackageLocation): TLocationRates => {
        return locationPackages[locationType].rates;
      };
    },

    getMountPackage: ({ locationPackages }: ILocationPackagesState) => (
      selectedPlan: IPlan,
      traffic: number,
      locationId: number,
      location: TPackageLocation
    ) => {
      const loc: any = locationPackages[location].packages.find(
        $package => $package.id === locationId
      );
      const tPlans = `${locationKeys[location].single}Plans`;
      const packages = loc[tPlans].find(
        ({ plan }: ILocationPlan) => plan.id === selectedPlan.id
      ).plan.packages;
      const monthPackage = packages.find(
        ({ traffic: trafficAmount, validity }: IPackage) =>
          trafficAmount.amount === traffic && validity?.period === 30
      );
      return monthPackage?.defaultPrice;
    }
  },

  mutations: {
    setTotalPackages(
      state: ILocationPackagesState,
      {
        totalPackages,
        locationType
      }: {
        totalPackages: number;
        locationType: TPackageLocation;
      }
    ) {
      state.locationPackages[locationType].total = totalPackages;
    },

    setLocationPackages(
      state: ILocationPackagesState,
      {
        packages,
        locationType
      }: {
        packages: ILocationPackage[];
        locationType: TPackageLocation;
      }
    ) {
      state.locationPackages[locationType].packages = packages;
    },

    setPackagesRates(
      state: ILocationPackagesState,
      {
        locationType,
        rates
      }: {
        locationType: TPackageLocation;
        rates: TLocationRates;
      }
    ) {
      state.locationPackages[locationType].rates = rates;
    }
  },

  actions: {
    async fetchLocationPackages(
      { commit }: { commit: Commit },
      options: fetchLocationPackagesDto
    ): Promise<IFetchLocationPackagesResponse> {
      const {
        data: packages = [],
        total,
        ...response
      } = await api.fetchLocationPackages(options);

      const locationType: TPackageLocation =
        options.locationType || packageLocationTypes.countries;

      commit("setLocationPackages", { packages, locationType });
      commit("setTotalPackages", { locationType, totalPackages: total });

      return {
        data: packages,
        ...response
      };
    },

    async fetchLocationPackagesRates(
      { commit }: { commit: Commit },
      options: fetchLocationPackagesRatesDto
    ): Promise<IFetchLocationPackagesRatesResponse> {
      const {
        data: rates = [],
        ...response
      } = await api.fetchLocationPackagesRates(options);

      const locationType: TPackageLocation =
        options.locationType || packageLocationTypes.countries;

      commit("setPackagesRates", { locationType, rates: rates });

      return {
        data: rates,
        ...response
      };
    },

    async updateLocationPackages(
      {
        commit
      }: {
        commit: Commit;
      },
      data: ILocationPackageListItemEditDto
    ) {
      const {
        locationId,
        title,
        zone_i_product,
        discountAmount,
        currentLocationName,
        packageId,
        defaultPrice,
        validity,
        purchaseType,
        currentPlans,
        dataType,
        priceWithDiscount,
        discount,
        packageCommission,
        traffic
      } = data;

      const price = Number(defaultPrice) || -1;
      const requestData: IUpdateLocationPackageDto = {
        [currentLocationName]: locationId,
        title,
        product: zone_i_product || -1,
        defaultPrice: price,
        priceWithDiscount: priceWithDiscount || price,
        discountAmount: Number(discountAmount),
        validity: validity?.id || -1,
        purchaseType: purchaseType?.id || -1,
        dataType: dataType?.id || -1,
        plan: currentPlans.find(({ selected }) => selected)?.id || -1,
        discount,
        commission: packageCommission || 0,
        traffic
      };

      if (packageId) {
        requestData.id = packageId;
      }

      return await api.updateLocationPackages(requestData);
    },

    async removeLocationPackage(
      _: any,
      options: IRemoveLocationPackageDto
    ): Promise<IApiResponse> {
      return await api.removeLocationPackage(options);
    },
    clearRatesStore({ commit }: { commit: Commit }) {
      for (const value of Object.values(locationKeys)) {
        commit("setPackagesRates", { locationType: value.multiple, rates: [] });
      }
    }
  }
};
