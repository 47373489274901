import { INetworkGenerationItem } from "@/api/services/packages/esim/location-packages/types";
import { ActionContext } from "vuex";
import { api } from "@/api/Api";
import { rootState } from "@/store/rootState";
import { IUpdateCarrierDto} from "@/api/services/packages/esim/carriers/types";
import { IPlan } from "@/api/interfaces/payments/common";

export interface ICarrierRate {
  id: number;
  planId: number;
  operatorId: number;
  rate: number;
  plan?: IPlan;
}

export interface ICarrier {
  countryId: number;
  id: number;
  name: string;
  image: string;
  active: boolean;
  netGens: INetworkGenerationItem[];
  rates: ICarrierRate[];
}

interface IUpdateCarrierPayload extends IUpdateCarrierDto {
  isCreate: boolean;
}

export interface ICarriersState {
  [countryId: number]: ICarrier[];
}

const initialState: ICarriersState = {};

export const carriers = {
  state(): ICarriersState {
    return { ...initialState };
  },

  getters: {
    getCarriers(state: ICarriersState): (countryId: number) => ICarrier[] {
      return countryId => {
        return state[countryId] || [];
      };
    }
  },

  mutations: {
    setCarriers(
      state: ICarriersState,
      { countryId, carriers }: { countryId: number; carriers: ICarrier[] }
    ) {
      state[countryId] = carriers;
    }
  },

  actions: {
    async getCarriers(
      ctx: ActionContext<ICarriersState, rootState>,
      { countryId }: { countryId: number }
    ) {
      const existCarriers: ICarrier[] = ctx.getters.getCarriers(countryId);

      if (existCarriers.length) {
        return existCarriers;
      }

      const { data: carriers } = await api.fetchCarriers(countryId);

      ctx.commit("setCarriers", {
        countryId,
        carriers
      });

      return carriers;
    },

    async updateCarriers(
      ctx: ActionContext<ICarriersState, rootState>,
      data: IUpdateCarrierPayload
    ) {
      const { isCreate, ...reqData } = data;
      return await api.updateCarriers(reqData, isCreate);
    },

    async deleteCarriers(
      ctx: ActionContext<ICarriersState, rootState>,
      id: number
    ) {
      return await api.deleteCarriers(id);
    }
  }
};
