import { Store } from "vuex";
import { Ref } from "vue";
export enum esimDirectoriesTitles {
  validityPeriod = "Validity period",
  purchaseType = "Purchase type",
  dataTypes = "Data types",
  networkGeneration = "Network generation",
  plans = "Plans",
  esimTypes = "Esim Types",
  esimServers = "Esim Servers",
  esimZones = "Esim Zones",
  trafficAmount = "Traffic Amount",
  esimServersFree = ""
}

export enum esimDirectoriesTypes {
  validityPeriod = "validityPeriod",
  purchaseType = "purchaseType",
  dataTypes = "dataTypes",
  networkGeneration = "networkGeneration",
  plans = "plans",
  esimTypes = "esimTypes",
  esimServers = "esimServers",
  esimZones = "esimZones",
  trafficAmount = "trafficAmount",
  esimServersFree = "esimServersFree"
}

export enum esimDirectoriesGetLinks {
  validityPeriod = "esim-directory/validity-types",
  purchaseType = "esim-directory/purchase-types",
  dataTypes = "esim-directory/data-types",
  networkGeneration = "esim-directory/network-gen",
  plans = "esim-directory/plans",
  esimTypes = "esim/types/all",
  esimServers = "esim/servers/all",
  esimZones = "esim-directory/esim-zones",
  trafficAmount = "esim-directory/esim-package/traffic-amount",
  esimServersFree = "esim/server/free"
}

export enum esimDirectoriesEditLinks {
  validityPeriod = "esim-directory/validity-types",
  purchaseType = "esim-directory/purchase-types",
  dataTypes = "esim-directory/data-types",
  networkGeneration = "esim-directory/network-gen",
  plans = "esim-directory/plans",
  esimTypes = "esim/types",
  esimServers = "esim/server",
  esimZones = "esim-directory/esim-zones",
  trafficAmount = "esim-directory/esim-package/traffic-amount",
  esimServersFree = "esim/server/free"
}

export enum esimDirectoriesAddLinks {
  validityPeriod = "esim-directory/validity-types",
  purchaseType = "esim-directory/purchase-types",
  dataTypes = "esim-directory/data-types",
  networkGeneration = "esim-directory/network-gen",
  plans = "esim-directory/plans",
  esimTypes = "esim/types",
  esimServers = "esim/server",
  esimZones = "esim-directory/esim-zones",
  trafficAmount = "esim-directory/esim-package/traffic-amount",
  esimServersFree = "esim/server/free"
}

export enum esimDirectoriesDeleteLinks {
  validityPeriod = "esim-directory/validity-types",
  purchaseType = "esim-directory/purchase-types",
  dataTypes = "esim-directory/data-types",
  networkGeneration = "esim-directory/network-gen",
  plans = "esim-directory/plans",
  esimTypes = "esim/types",
  esimServers = "esim/server",
  esimZones = "esim-directory/esim-zones",
  trafficAmount = "esim-directory/esim-package/traffic-amount",
  esimServersFree = "esim/server/free"
}

export const esimDirectoriesAddImageLink = "esim-directory/upload-image/0";

export const esimDirectoriesTitleArray = [
  esimDirectoriesTitles.validityPeriod,
  esimDirectoriesTitles.purchaseType,
  esimDirectoriesTitles.dataTypes,
  esimDirectoriesTitles.networkGeneration,
  esimDirectoriesTitles.plans,
  esimDirectoriesTitles.esimTypes,
  esimDirectoriesTitles.esimServers,
  esimDirectoriesTitles.esimZones,
];

export type TEsimDirectoryTypes = keyof typeof esimDirectoriesTypes;

export interface IFetchDirectoriesParams {
  directoryType: TEsimDirectoryTypes;
  store: Store<any>;
}

export interface esimDirectoriesModalsInterface {
  esimTypesModal: boolean;
  esimZonesModal: boolean;
  esimServersModal: boolean;
  directoryModal: boolean;
  validityPeriodModal: boolean;
  plansModal: boolean;
  dataTypesModal: boolean;
  purchaseTypeModal: boolean;
  networkGenerationModal: boolean;
  esimServersFreeModal: boolean;
  trafficAmountModal: boolean;
  deleteModal: boolean;
}
