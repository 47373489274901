import { packageLocationTypes } from "@/hooks/esim/location-packages/types/locationPackage.types";

export const locationKeys = {
  [packageLocationTypes.countries]: {
    multiple: "countries",
    single: "country"
  },

  [packageLocationTypes.regions]: {
    multiple: "regions",
    single: "region"
  },

  [packageLocationTypes.subregions]: {
    multiple: "subregions",
    single: "subregion"
  }
};

export type TLocationName = "country" | "region" | "subregion";
